.bursts {
  //background-color: $color-4;
  padding: 5px 0;
    width: 100%;
    .bursts-container {
        @extend .flex;
        .burst {
            //flex: 1;
            position: relative;
            .text-container, .overlay {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
            .text-container {
              & > div {
                    width: 100%;
                    height: 100%;
                    @extend .flex;
                    @extend .flex-column;
                    @extend .justify-center;
                    //@extend .items-center;
                    transition: all 0.2s ease-out; // OUT effect
                }
            }
            .overlay {
                opacity: 0;
                transition: all 0.7s ease-out; // OUT effect
            }
            h3, p {
                color: $color-white;
                transition: all 0.2s ease-out; // OUT effect
                //text-shadow: 0 0 5px $color-black;
            }
            h3 {
                @extend .font-1-medium;
                font-size: $font-size-50;
                font-weight: 500;
                color: $color-white;
                text-shadow: 0 0 10px $color-black;
                text-transform: uppercase;
            }
            p {
                @extend .font-1-normal;
                font-size: $font-size-42;
                font-weight: normal;
                color: $color-white;
                text-shadow: 0 0 10px $color-black;
                line-height: 1.1;
                margin-bottom: 0;
            }
            &.burst-center {
              h3, p {
                  text-align: center;
                  color: $color-white;
                  text-shadow: 0 0 10px $color-black;
                  transition: all 0.2s ease-out; // OUT effect
              }
            }
            &.burst-left {
              .text-container {
                & > div {
                      @extend .flex-column;
                      @extend .justify-center;
                      @extend .items-center;
                      transition: all 0.2s ease-out; // OUT effect
                  }
              }
            }
        }
        a:hover .burst {
            .overlay {
                opacity: 1;
                transition: all 0.3s ease; // IN effect
            }
            h3, p {
                color: $color-black;
                text-shadow: 0 0 10px $color-white;
                transition: all 0.3s ease; // IN effect
                //text-shadow: 0 0 5px $color-black;
            }
            &.burst-center {
              h3, p {
                    color: $color-white;
                    text-shadow: 0 0 10px $color-white;
                    transition: all 0.3s ease; // IN effect
              }
            }
        }
    }
}
@media screen and (max-width: 1635px) {
    .bursts {
        .bursts-container {
            .burst {
                h3 {
                    font-size: 2rem;
                }
                p {
                    font-size: 1.8rem;
                }
            }
        }
    }
    @media screen and (max-width: 1335px) {
        .bursts {
            .bursts-container {
                .burst {
                    h3 {
                        font-size: 1.8rem;
                    }
                    p {
                        font-size: 1.55rem;
                    }
                }
            }
        }
        @media screen and (max-width: 1024px) {
        .bursts {
            .bursts-container {
                .burst {
                    h3 {
                        font-size: 1.5rem;
                    }
                    p {
                        font-size: 1.3rem;
                    }
                }
            }
        }
            @media screen and (max-width: $size-slider-breakpoint) {
                .bursts {
                    .bursts-container {
                        flex-direction: column;
                        .burst {
                            h3 {
                                text-align: center;
                                font-size: 2rem;
                            }
                            p {
                                text-align: center;
                                font-size: 1.5rem;
                            }
                        }
                    }
                }
            }
            @media screen and (max-width: $size-sm-max) {
               .bursts {
                    .bursts-container {
                        .burst {
                            h3 {
                                font-size: 1.3rem;
                            }
                            p {
                                font-size: 1.2rem;
                            }
                        }
                    }
                }
            }
            @media screen and (max-width: $size-xs-max) {
               .bursts {
                    .bursts-container {
                        a.px1 {
                            padding: 0.25rem 0;
                        }
                        .burst {
                            h3 {
                                font-size: 2rem;
                            }
                            p {
                                font-size: 1.55rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
