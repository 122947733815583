// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-text {
    padding: 75px 5%;
    h3 {
        font-weight: 600;
        font-size: $font-size-32;
        color: #024b94;
        padding-bottom: 20px;
    }
    p {
        font-weight: normal;
        font-size: $font-size-20;
        color: #444444;
        line-height: 1.8;
        margin: 0;
        span {
            font-weight: bold;
            color: #024b94;
        }
    }
}

#section-images {
    padding: 0 10px;
    .image-side {
        width: 33.3333%;
    }
    @media screen and (max-width: $size-xs-max) {
        flex-direction: column;
        .image-side {
            width: 100%;
        }
        .image-side.mx2 {
            margin: 0.5rem 0;
        }
    }
}

#section-text-logo {
    padding: 50px 5% 75px 5%;
    h3 {
        font-weight: 600;
        font-size: $font-size-32;
        color: #024b94;
        padding-bottom: 20px;
    }
    p {
        font-weight: normal;
        font-size: $font-size-20;
        color: #444444;
        text-align: justify;
        line-height: 1.8;
        margin: 0;
    }
}

#section-background {
    padding: 50px 5%;
    background: #024b94;
    h4 {
        font-weight: normal;
        font-size: $font-size-28;
        color: $color-white;
        line-height: 1.3;
        span {
            font-weight: 600;
            font-size: $font-size-32;
        }
    }
}

#section-certifications {
    padding: 75px 5%;
    border-bottom: 48px solid #024b94;
    h3 {
        font-weight: 600;
        font-size: $font-size-32;
        color: #024b94;
        padding-bottom: 35px;
    }
    @media screen and (max-width: 1024px) {
        .section-logo {
            flex-wrap: wrap;
            .logo {
                width: 33.3333%;
                padding: 25px;
            }
        }
        @media screen and (max-width: $size-xs-max) {
            h3 {
                padding-bottom: 55px;
            }
            .section-logo {
                justify-content: center;
                .logo {
                    width: 55%;
                    padding: 0 0 45px 0;
                }
            }
        }
    }
}

/*******************************************************************************
* QUI NOUS SOMMES
*******************************************************************************/

#section-text-images {
    padding: 75px 5%;
    a.btn-link {
        margin-top: 50px;
        background: #024b94;
        display: flex;
        width: 260px;
        height: 80px;
        border-radius: 15px;
        transition: 0.3s;
        h5 {
            font-weight: normal;
            font-size: $font-size-18;
            color: $color-white;
        }
        &:hover {
            background: #000;
            transition: 0.3s;
        }
    }
    .left-side {
        width: 50%;
        margin-right: 50px;
        h3 {
            font-weight: 600;
            font-size: $font-size-32;
            color: #024b94;
            padding-bottom: 20px;
        }
        p {
            font-weight: normal;
            font-size: $font-size-20;
            color: #444444;
            line-height: 1.8;
            text-align: justify;
            margin: 0;
            span {
                font-weight: bold;
                color: #024b94;
            }
        }
    }
    .right-side {
        width: 50%;
    }
    @media screen and (max-width: 1450px) {
        flex-direction: column;
        .left-side {
            width: 100%;
            margin: 0 0 35px 0;
        }
        .right-side {
            width: 65%;
        }
        @media screen and (max-width: $size-sm-max) {
            .right-side {
                width: 100%;
            }
            @media screen and (max-width: $size-xs-max) {
                a.btn-link {
                    width: 200px;
                    height: 65px;
                }
            }
        }
    }
}

/*******************************************************************************
* TOITURE DE BARDEAUX
*******************************************************************************/

#section-text.bardeaux {
    padding: 75px 5% 0 5%;
}

#section-text-images.bardeaux {
    padding: 50px 5% 75px 5%;
}

#section-choix {
    padding: 75px 5%;
    h3 {
        font-weight: 600;
        font-size: $font-size-32;
        color: #024b94;
        padding-bottom: 20px;
    }
    .image {
        .img-responsive {
            width: 100%;
        }
    }
    .bloc {
        width: 25%;
        h4 {
            font-weight: bold;
            font-size: $font-size-16;
            color: #202124;
            padding-bottom: 20px;
        }
        p {
            font-size: $font-size-16;
            color: #A6ADB4;
        }
    }
    @media screen and (max-width: 1500px) {
        .section-bloc {
            flex-wrap: wrap;
            .bloc {
                width: 35%;
                padding-bottom: 50px;
                margin: 0 auto;
            }
        }
        @media screen and (max-width: 1024px) {
            .section-bloc {
                .bloc {
                    width: 42%;
                }
            }
            @media screen and (max-width: $size-sm-max) {
                .section-bloc {
                    .bloc {
                        width: 47%;
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    .section-bloc {
                        .bloc {
                            width: 100%;
                            padding-bottom: 35px;
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#section-realisations {
    padding: 0 5% 75px 5%;
    border-bottom: 50px solid #024b94;
    .realisations.zoom-gallery {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;
        a.item {
            padding-bottom: 35px;
            .title {
                background: #024b94;
                padding: 15px 30px;
                margin-bottom: 15px;
                h3 {
                    font-weight: 600;
                    font-size: $font-size-26;
                    color: $color-white;
                    transition: 0.3s;
                }
            }
            .image {
                position: relative;
                margin-bottom: 15px;
                .img-responsive {
                    width: 100%;
                }
                .img-plus {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    opacity: 0;
                    transition: 0.5s;
                }
                .overlay {
                    background-color: rgba(255, 255, 255, 0.6);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0;
                    transition: all 0.3s ease-out;
                }
            }
            p {
                font-weight: normal;
                font-size: $font-size-20;
                color: #024b94;
                line-height: 1.5;
                transition: 0.3s;
            }
            &:hover {
                .image {
                    .overlay {
                        opacity: 1;
                    }
                    .img-plus {
                        opacity: 1;
                        transition: 0.5s;
                    }
                }
            }
        }
        @media screen and (max-width: $size-md-max){
            grid-template-columns: repeat(2, 1fr);
            @media screen and (max-width: $size-xs-max){
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }

    #blocIntro_promotions {
        h3 {
            color: #fff;
            text-transform: initial;
        }
    }
}

/*******************************************************************************
* AUTRES SERVICES
*******************************************************************************/

#section-images-text {
    padding: 25px 5%;
    a.btn-link {
        margin-top: 50px;
        background: #024b94;
        display: flex;
        width: 260px;
        height: 80px;
        border-radius: 15px;
        transition: 0.3s;
        h5 {
            font-weight: normal;
            font-size: $font-size-18;
            color: $color-white;
        }
        &:hover {
            background: #000;
            transition: 0.3s;
        }
    }
    .left-side {
        width: 50%;
    }
    .right-side {
        width: 50%;
        margin-left: 50px;
        h3 {
            font-weight: 600;
            font-size: $font-size-32;
            color: #024b94;
            padding-bottom: 20px;
        }
        p {
            font-weight: normal;
            font-size: $font-size-20;
            color: #444444;
            line-height: 1.8;
            text-align: justify;
            margin: 0;
            span {
                font-weight: bold;
                color: #024b94;
            }
        }
    }
    @media screen and (max-width: 1450px) {
        padding: 0 5%;
        flex-direction: column-reverse;
        .right-side {
            width: 100%;
            margin: 0 0 35px 0;
        }
        .left-side {
            width: 65%;
        }
        @media screen and (max-width: $size-sm-max) {
            .left-side {
                width: 100%;
            }
            @media screen and (max-width: $size-xs-max) {
                a.btn-link {
                    width: 200px;
                    height: 65px;
                }
            }
        }
    }
}

/*******************************************************************************
* SOUMISSION
*******************************************************************************/

section#section-form {
    padding-top: 25px;
}
@media screen and (max-width: 1150px) {
    .col-sm-offset-2 {
        margin-left: 11.66667%;
    }
    @media screen and (max-width: 1024px) {
        form div.label-wrapper.bouton {
            width: 835px;
        }
        @media screen and (max-width: $size-sm-max) {
            form div.label-wrapper.bouton {
                width: 810px;
            }
            .col-sm-offset-2 {
                margin-left: 5%;
            }
            .col-sm-9 {
                width: 90%;
            }
            .form-group.col-xs-12.col-sm-5.col-sm-offset-2 {
                width: 50%;
            }
            .form-group.col-xs-12.col-sm-4 {
                width: 40%;
            }
            @media screen and (max-width: $size-xs-max) {
                .col-sm-offset-2 {
                    margin-left: 2%;
                }
                .col-sm-9 {
                    width: 96%;
                }
                .flex-auto {
                    width: 100%;
                }
                .form-group.col-xs-12.col-sm-5.col-sm-offset-2 {
                    width: 90%;
                }
                .form-group.col-xs-12.col-sm-4 {
                    width: 90%;
                    margin-top: -20px;
                    margin-left: 2%;
                }
                .section-btn.flex.items-center.pt5 {
                    flex-direction: column;
                }
                form label.filedoc {
                    margin: 5px auto;
                }
            }
        }
    }
}
/*******************************************************************************
* EMPLOI
*******************************************************************************/

#section-emploi {
    padding: 75px 5%;
    div.section-wrapper {
        display: inline-grid;
        margin: auto;
        width: 90%;
        max-width: 1600px;;
        justify-items: stretch;
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
        @media screen and (max-width: $size-sm-max){
            grid-template-columns: 1fr;
        }
    }
    div.left-side, div.right-side {
        width: 50%;
        @media screen and (max-width: $size-xs-max){
          width: 100%;
        }
    }

    section.emplois-liste {
        display: flex;
        flex-wrap: wrap;
        div.item-poste {
            display: inline-block;
            margin-bottom: 60px;
            background-color: #04426F1A;
            transition: .5s;
            width: 49%;

            div.title {
                background-color: #04426F;
                padding: 30px 7%;
                h4 {
                    color: #DEEFE1;
                    font-size: 20px;
                    font-weight: bold;
                    text-transform: initial;
                }
                @media screen and (max-width: 1325px){
                    padding: 30px 5%;
                }
            }
            div.soustitre {
                padding: 30px 7%;
                .icon {
                    font-size: 18px;
                    font-weight: bold;
                    text-transform: uppercase;
                    color: #04426F;
                    margin-right: 50px;
                    .fa {
                        font-size: 20px;
                        margin-right: 10px;
                    }
                    @media screen and (max-width: 1420px){
                        margin-right: 25px;
                        @media screen and (max-width: 1270px){
                            margin-right: 0;
                            width: 100%;
                            margin-bottom: 15px;
                            @media screen and (max-width: 1100px){
                                margin-right: 25px;
                                width: initial;
                                margin-bottom: 0;
                                @media screen and (max-width: $size-xs-max){
                                    margin-right: 0;
                                    width: 100%;
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: 1325px){
                    padding: 30px 5%;
                }
            }
            div.content-full {
                padding: 0 7% 30px 7%;
                h4, h3, h2 {
                    font-size: 18px;
                    color: #04426F;
                    padding: 15px 0;
                    font-weight: bold;
                }
                p, li {
                    color: #04426F;
                    font-weight: normal;
                    font-size: 16px;
                    padding-left: 25px;
                    @media screen and (max-width: 1420px){
                        padding-left: 0;
                    }
                }
                .btn-wrapper {
                    max-width: 200px;
                    margin-top: 50px;
                    .btn {
                        padding: 20px 5px;
                        background-color: #04426F;
                    }
                }
                @media screen and (max-width: 1325px){
                    padding: 0 5% 30px 5%;
                }
            }
            &:nth-child(odd) {
                margin-right: 15px;
                @media screen and (max-width: 1100px){
                    margin-right: 0;
                }
            }
            @media screen and (max-width: 1100px){
                width: 100%;
                margin: 0 0 15px 0;
            }
        }
    }
}

section.section-form-emploi {
    padding: 30px 8%;
    background-color: #04426F1A;
    h3 {
        font-weight: bold;
        color: #3D4251;
        font-size: $font-size-30;
        padding-bottom: 15px;
        @media screen and (max-width: 360px) {
            font-size: 1.4rem;
        }
    }
    form label.filedoc {
        background: #04426F;
        width: 300px;
    }
    .btn {
        background-color: #04426F !important;
    }
    @media screen and (max-width: $size-xs-max) {
        .pl0 , .pr0, {
            padding: 0!important;
        }
        form label.filedoc {
            width: 230px;
        }
        form .buttons {
            justify-content: center;
        }
        .btn-submit {
            margin-top: 15px;
            .btn.submit-emploi {
                height: 45px;
            }
        }
    }
    @media screen and (max-width: 1325px){
        padding: 30px 5%;
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    padding: 100px 5%;
    border-bottom: 50px solid #024b94;
    .container {
        width: 1300px;
        .section-info.flex {
            .left-side {
                width: 50%;
                margin-right: 50px;
            }
            .right-side {
                width: 50%;
            }
            h3 {
                font-weight: 500;
                font-size: $font-size-30;
                color: #024b94;
                text-transform: initial;
            }
            h3.tel {
                line-height: 1.5;
            }
        }
        .section-heures-map {
            padding-top: 50px;
            .left-side {
                width: 50%;
                margin-right: 50px;
                .info {
                    padding-top: 12px;
                }
            }
            .right-side {
                width: 50%;
            }
            h4 {
                font-weight: 500;
                font-size: $font-size-24;
                color: #024b94;
            }
        }
    }
    @media screen and (max-width: 1370px) {
        .container {
            width: 100%;
        }
        @media screen and (max-width: 1200px) {
            .container {
                .section-heures-map {
                    flex-direction: column;
                    .left-side {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 25px;
                    }
                    .right-side {
                        width: 100%;
                    }
                }
            }
            @media screen and (max-width: $size-sm-max) {
                .container {
                    .section-info.flex {
                        flex-direction: column;
                        .left-side {
                            width: 100%;
                            margin-bottom: 25px;
                        }
                        .right-side {
                            width: 100%;
                        }
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    padding: 65px 5%;
                    .container {
                        padding: 0;
                        .section-info.flex {
                            .bloc.flex {
                                flex-direction: column;
                                align-items: center;
                                .icon.mr5 {
                                    margin: 0 0 5px 0;
                                }
                            }
                            .right-side {
                                .bloc.flex.items-center.pb4 {
                                    padding-bottom: 25px;
                                }
                            }
                        }
                        .section-heures-map {
                            .icon.mr3 {
                                display: none;
                            }
                        }
                    }
                    @media screen and (max-width: 360px) {
                        .container {
                            .section-heures-map {
                                h4 {
                                    font-size: 1rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
   

}

@media screen and (max-width: 1024px) {
   

}

@media screen and (max-width: $size-sm-max) {
   

}

@media screen and (max-width: $size-xs-max) {
   


}
